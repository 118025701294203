import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            © 2024 9A5N. Sva prava pridržana.
        </footer>
    );
};

export default Footer;
