import React, { useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';
import { Link, animateScroll as scroll } from 'react-scroll';

const NavMenu = [
    { id: 1, title: "Početna", link: "home" },
    { id: 2, title: "Usluge", link: "services" },
    { id: 3, title: "O nama", link: "about" },
    { id: 4, title: "Kontakt", link: "contact" },
];

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <header className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-opacity-80 backdrop-blur-lg py-4 bg-white shadow-md' : 'bg-white py-4'}`}>
                <div className="container mx-auto flex justify-between items-center px-4">
                    <div onClick={scrollToTop} className="text-2xl font-bold text-yellow-500 cursor-pointer">
                        9A5N
                    </div>
                    <div className="hidden md:flex space-x-8">
                        {NavMenu.map((item) => (
                            <li key={item.id} className="list-none">
                                <Link
                                    to={item.link}
                                    smooth={true}
                                    duration={500}
                                    className="hover:text-yellow-500 text-xl font-semibold text-black transition duration-300 cursor-pointer"
                                >
                                    {item.title}
                                </Link>
                            </li>
                        ))}
                    </div>
                    <div className="md:hidden">
                        <div onClick={toggleMobileMenu} className="cursor-pointer text-3xl text-black">
                            <GiHamburgerMenu />
                        </div>
                    </div>
                </div>
            </header>
            <div className={`fixed top-0 left-0 w-3/4 h-full bg-white z-40 transform ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out shadow-lg`}>
                <div className="flex flex-col h-full relative">
                    <div className="flex justify-between items-center px-4 py-4">
                        <div className="text-2xl font-bold text-yellow-500">
                            9A5N
                        </div>
                        <div onClick={toggleMobileMenu} className="cursor-pointer text-3xl text-black">
                            <IoMdClose />
                        </div>
                    </div>
                    <div className="flex flex-col items-start px-4 space-y-6 mt-4">
                        {NavMenu.map((item) => (
                            <li key={item.id} className="list-none w-full">
                                <Link
                                    to={item.link}
                                    smooth={true}
                                    duration={500}
                                    className="text-2xl font-semibold text-black hover:text-yellow-500 transition duration-300 cursor-pointer block w-full"
                                    onClick={toggleMobileMenu}
                                >
                                    {item.title}
                                    <span className="ml-2 text-lg text-gray-400">></span>
                                </Link>
                            </li>
                        ))}
                    </div>
                    <div className="mt-auto px-4 py-4 border-t border-gray-200">
                        <a href="mailto:info@9a5n.hr" className="text-gray-600 hover:text-black transition duration-300">
                            info@9a5n.hr
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
