import React, { useState } from 'react';
import { FaBroom, FaSeedling, FaNetworkWired, FaHandsHelping, FaCheckCircle, FaShieldAlt, FaRecycle, FaLeaf, FaStar, FaThumbsUp, FaHeart } from 'react-icons/fa';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Modal from 'react-modal';

const AnimatedItem = ({ icon: Icon, title, description, delay, className, ribbonText, onClick }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.div
            ref={ref}
            className={`${className} card`}
            initial="hidden"
            animate={controls}
            transition={{ duration: 0.5, delay }}
            variants={variants}
            onClick={onClick}
        >
            {ribbonText && <div className="ribbon">{ribbonText}</div>}
            <Icon className="text-3xl text-yellow-500 mb-4 mx-auto" />
            {title && <h3 className="text-2xl font-semibold mb-2">{title}</h3>}
            <p>{description}</p>
        </motion.div>
    );
};

const Home = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const openModal = (content) => {
        setModalContent(content);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const scrollToContact = () => {
        document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <section id="home" className="text-center py-20 bg-gray-100">
            <div className="px-4">
                <motion.h1
                    className="text-4xl font-bold mb-6 fade-in-up"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Dobrodošli na 9A5N
                </motion.h1>
                <motion.p
                    className="text-xl max-w-2xl mx-auto mb-6 fade-in-up"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                >
                    Vaš provjereni servis za čišćenje i održavanje prostora.
                    Nudimo usluge čišćenja kuća, stanova, ureda i stubišta, kao i uređivanje vrta i okućnica te izradu mrežnih kućnih instalacija i mrežnih rješenja.
                </motion.p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6 px-4">
                <AnimatedItem
                    icon={FaBroom}
                    title="Čišćenje stubišta"
                    description="Profesionalno čišćenje stubišta."
                    delay={0.2}
                    className="relative bg-white p-6 rounded shadow-md"
                    ribbonText="Popularno"
                    onClick={() => openModal({ title: "Čišćenje stubišta", icon: FaBroom, content: ["Profesionalno čišćenje stubišta", "Korištenje ekološki prihvatljivih proizvoda", "Detaljno čišćenje podova, stepenica, rukohvata i zidova", "Osiguravanje čistoće i sigurnosti zajedničkih prostora"] })}
                />
                <AnimatedItem
                    icon={FaSeedling}
                    title="Uređivanje vrta"
                    description="Održavanje vrta, košenje trave, rezanje grana i piljenje drva."
                    delay={0.3}
                    className="relative bg-white p-6 rounded shadow-md"
                    onClick={() => openModal({ title: "Uređivanje vrta", icon: FaSeedling, content: ["Košenje trave", "Rezanje grana", "Sadnja cvijeća", "Ekološki prihvatljive metode vrtlarstva"] })}
                />
                <AnimatedItem
                    icon={FaNetworkWired}
                    title="Mrežne instalacije"
                    description="Izrada mrežnih kućnih instalacija i mrežnih rješenja."
                    delay={0.4}
                    className="relative bg-white p-6 rounded shadow-md"
                    ribbonText="Novo"
                    onClick={() => openModal({ title: "Mrežne instalacije", icon: FaNetworkWired, content: ["Izrada kućnih mrežnih instalacija", "Profesionalna usluga instalacije mreže", "Korištenje najnovijih tehnologija", "Optimalna povezanost i sigurnost"] })}
                />
                <AnimatedItem
                    icon={FaHandsHelping}
                    title="Mali kućanski poslovi"
                    description="Sastavljanje namještaja, promjena žarulja i ostali kućanski poslovi."
                    delay={0.5}
                    className="relative bg-white p-6 rounded shadow-md"
                    onClick={() => openModal({ title: "Mali kućanski poslovi", icon: FaHandsHelping, content: ["Sastavljanje namještaja", "Promjena žarulja", "Popravci", "Postavljanje polica", "Zamjena slavina", "Ostali manji kućanski poslovi"] })}
                />
            </div>
            <motion.button
                onClick={scrollToContact}
                className="bg-yellow-500 text-white px-6 py-2 rounded hover:bg-yellow-600 transition duration-300 fade-in-up"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
            >
                Kontaktirajte nas
            </motion.button>

            <div className="mt-12 bg-white py-12">
                <motion.h2
                    className="text-3xl font-bold mb-6 fade-in-up"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Zašto odabrati nas?
                </motion.h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
                    <motion.div className="flex items-center fade-in-up card" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.1 }}>
                        <FaHandsHelping className="text-3xl text-yellow-500 mr-4" />
                        <p className="text-lg">Profesionalna i prijateljska usluga</p>
                    </motion.div>
                    <motion.div className="flex items-center fade-in-up card" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.2 }}>
                        <FaCheckCircle className="text-3xl text-yellow-500 mr-4" />
                        <p className="text-lg">Visoki standardi kvalitete</p>
                    </motion.div>
                    <motion.div className="flex items-center fade-in-up card" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.3 }}>
                        <FaShieldAlt className="text-3xl text-yellow-500 mr-4" />
                        <p className="text-lg">Pouzdanost i sigurnost</p>
                    </motion.div>
                    <motion.div className="flex items-center fade-in-up card" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.4 }}>
                        <FaRecycle className="text-3xl text-yellow-500 mr-4" />
                        <p className="text-lg">Ekološki prihvatljive metode</p>
                    </motion.div>
                    <motion.div className="flex items-center fade-in-up card" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.5 }}>
                        <FaLeaf className="text-3xl text-yellow-500 mr-4" />
                        <p className="text-lg">Održivo vrtlarstvo</p>
                    </motion.div>
                </div>
            </div>

            <div className="mt-12 bg-gray-100 py-12">
                <motion.h2
                    className="text-3xl font-bold mb-6 fade-in-up"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Naši Klijenti
                </motion.h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
                    <AnimatedItem
                        icon={FaStar}
                        description={<><span className="text-lg italic">"9A5N je najbolji servis za čišćenje s kojim smo ikada radili. Preporučujemo ih svima!"</span><p className="text-gray-500 mt-4">- Ana K.</p></>}
                        delay={0.1}
                        className="bg-white p-6 rounded shadow-md"
                    />
                    <AnimatedItem
                        icon={FaThumbsUp}
                        description={<><span className="text-lg italic">"Izuzetno zadovoljni uslugom uređenja vrta. Naš vrt nikada nije izgledao bolje!"</span><p className="text-gray-500 mt-4">- Marko P.</p></>}
                        delay={0.2}
                        className="bg-white p-6 rounded shadow-md"
                    />
                    <AnimatedItem
                        icon={FaHeart}
                        description={<><span className="text-lg italic">"Profesionalno, pouzdano i prijateljski. Uvijek su tu kada ih trebamo."</span><p className="text-gray-500 mt-4">- Ivana S.</p></>}
                        delay={0.3}
                        className="bg-white p-6 rounded shadow-md"
                    />
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Detalji usluge"
                className="modal modal-content"
                overlayClassName="modal-overlay"
                ariaHideApp={false}
            >
                <div className="flex items-center mb-4">
                    {modalContent.icon && <modalContent.icon className="text-3xl text-yellow-500 mr-4" />}
                    <h2 className="text-2xl font-bold">{modalContent.title}</h2>
                </div>
                <ul className="list-disc pl-5 space-y-2">
                    {modalContent.content && modalContent.content.map((item, index) => (
                        <li key={index} className="text-lg flex items-start">
                            <FaCheckCircle className="text-yellow-500 mr-2 mt-1 text-xl" />
                            <span>{item}</span>
                        </li>
                    ))}
                </ul>
                <button onClick={closeModal} className="mt-4 bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition duration-300">Zatvori</button>
            </Modal>
        </section>
    );
};

export default Home;
