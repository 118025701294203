import React from 'react';
import Modal from 'react-modal';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import './App.css';

Modal.setAppElement('#root');

function App() {
    return (
        <div className="App" id="root">
            <Header />
            <main className="main-content pt-16">
                <Home />
                <Services />
                <About />
                <Contact />
            </main>
            <Footer />
            <ScrollToTop />
        </div>
    );
}

export default App;
