import React from 'react';
import { FaLeaf ,FaHandsHelping, FaShieldAlt, FaHistory, FaMedal, FaUsers, FaChartLine, FaHeartbeat, FaRegSmile } from 'react-icons/fa';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimatedItem = ({ icon: Icon, title, description, delay, className }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.div
            ref={ref}
            className={className}
            initial="hidden"
            animate={controls}
            transition={{ duration: 0.5, delay }}
            variants={variants}
        >
            <Icon className="text-3xl text-yellow-500 mb-4 mx-auto" />
            {title && <h3 className="text-2xl font-semibold mb-2">{title}</h3>}
            <p>{description}</p>
        </motion.div>
    );
};

const About = () => {
    return (
        <section id="about" className="text-center py-20 bg-gray-100">
            <div className="px-4">
                <motion.h1
                    className="text-4xl font-bold mb-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    O nama
                </motion.h1>
                <motion.p
                    className="text-xl max-w-2xl mx-auto mb-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                >
                    Obrt 9A5N specijaliziran je za čišćenje, održavanje vrtova i okućnica te izradu mrežnih kućnih instalacija. Naš tim stručnjaka posvećen je pružanju visokokvalitetnih usluga kako bi vaši prostori bili čisti i uredni. Dugogodišnje iskustvo i profesionalnost jamče vaše zadovoljstvo.
                </motion.p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6 px-4">
                <AnimatedItem
                    icon={FaHandsHelping}
                    title="Naša Misija"
                    description="Pružiti visoku kvalitetu usluga čišćenja i održavanja, uz iznimnu pažnju prema detaljima i zadovoljstvu naših klijenata."
                    delay={0.2}
                    className="bg-white p-6 rounded shadow-md"
                />
                <AnimatedItem
                    icon={FaShieldAlt}
                    title="Naše Vrijednosti"
                    description="Pouzdanost, sigurnost, ekološka osviještenost i profesionalnost u svakom aspektu našeg rada."
                    delay={0.3}
                    className="bg-white p-6 rounded shadow-md"
                />
                <AnimatedItem
                    icon={FaHistory}
                    title="Naša Povijest"
                    description="Više od 10 godina iskustva u pružanju vrhunskih usluga čišćenja i održavanja, s brojnim zadovoljnim klijentima."
                    delay={0.4}
                    className="bg-white p-6 rounded shadow-md"
                />
            </div>

            <div className="mt-12 bg-white py-12">
                <motion.h2
                    className="text-3xl font-bold mb-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Naš Tim
                </motion.h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
                    <AnimatedItem
                        icon={FaUsers}
                        title="Stručni Djelatnici"
                        description="Naš tim čine iskusni i kvalificirani stručnjaci koji su posvećeni pružanju vrhunske usluge."
                        delay={0.2}
                        className="bg-gray-100 p-8 rounded shadow-md"
                    />
                    <AnimatedItem
                        icon={FaMedal}
                        title="Kvaliteta i Izvrsnost"
                        description="Stalno se usavršavamo i pratimo najnovije trendove kako bismo osigurali najvišu razinu kvalitete usluga."
                        delay={0.3}
                        className="bg-gray-100 p-8 rounded shadow-md"
                    />
                    <AnimatedItem
                        icon={FaChartLine}
                        title="Rast i Razvoj"
                        description="Kontinuirano radimo na poboljšanju naših usluga i širenju našeg tima kako bismo zadovoljili rastuće potrebe naših klijenata."
                        delay={0.4}
                        className="bg-gray-100 p-8 rounded shadow-md"
                    />
                    <AnimatedItem
                        icon={FaHeartbeat}
                        title="Briga za Klijente"
                        description="Naš cilj je izgraditi dugotrajne odnose s klijentima pružajući im usluge koje nadmašuju njihova očekivanja."
                        delay={0.5}
                        className="bg-gray-100 p-8 rounded shadow-md"
                    />
                </div>
            </div>

            <div className="mt-12 bg-gray-100 py-12">
                <motion.h2
                    className="text-3xl font-bold mb-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Naše Prednosti
                </motion.h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
                    <AnimatedItem
                        icon={FaRegSmile}
                        description="Zadovoljstvo klijenata je naša najveća nagrada."
                        delay={0.1}
                        className="bg-white p-6 rounded shadow-md"
                    />
                    <AnimatedItem
                        icon={FaLeaf}
                        description="Koristimo ekološki prihvatljive proizvode."
                        delay={0.2}
                        className="bg-white p-6 rounded shadow-md"
                    />
                    <AnimatedItem
                        icon={FaShieldAlt}
                        description="Osiguravamo sigurnost i pouzdanost u svim našim uslugama."
                        delay={0.3}
                        className="bg-white p-6 rounded shadow-md"
                    />
                </div>
            </div>
        </section>
    );
};

export default About;
