import React from 'react';
import { FaBroom, FaSeedling, FaNetworkWired, FaHandsHelping, FaCheckCircle } from 'react-icons/fa';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ServiceItem = ({ icon: Icon, title, description, details, delay }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.div
            ref={ref}
            className="service-item text-left"
            initial="hidden"
            animate={controls}
            transition={{ duration: 0.5, delay }}
            variants={variants}
        >
            <div className="icon-wrapper">
                <Icon />
            </div>
            <h3>{title}</h3>
            <p>{description}</p>
            <ul>
                {details.map((detail, index) => (
                    <li key={index}>
                        <FaCheckCircle className="bullet-icon" />
                        <span>{detail}</span>
                    </li>
                ))}
            </ul>
        </motion.div>
    );
};

const Services = () => {
    return (
        <section id="services" className="text-center py-20 bg-white">
            <motion.h2
                className="text-4xl font-bold mb-10"
                initial="hidden"
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, y: 20 },
                    visible: { opacity: 1, y: 0 },
                }}
            >
                Naše Usluge
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
                <ServiceItem
                    icon={FaBroom}
                    title="Čišćenje stubišta"
                    description="Naš tim pruža profesionalno čišćenje stubišta koristeći ekološki prihvatljive proizvode."
                    details={[
                        "Profesionalno čišćenje stubišta",
                        "Korištenje ekološki prihvatljivih proizvoda",
                        "Detaljno čišćenje podova, stepenica, rukohvata i zidova",
                        "Osiguravanje čistoće i sigurnosti zajedničkih prostora"
                    ]}
                    delay={0.1}
                />
                <ServiceItem
                    icon={FaSeedling}
                    title="Uređivanje vrta"
                    description="Nudimo usluge održavanja vašeg vrta i okućnice."
                    details={[
                        "Košenje trave",
                        "Rezanje grana",
                        "Sadnja cvijeća",
                        "Ekološki prihvatljive metode vrtlarstva"
                    ]}
                    delay={0.2}
                />
                <ServiceItem
                    icon={FaNetworkWired}
                    title="Mrežne instalacije"
                    description="Izrada mrežnih kućnih instalacija i mrežnih rješenja."
                    details={[
                        "Izrada kućnih mrežnih instalacija",
                        "Profesionalna usluga instalacije mreže",
                        "Korištenje najnovijih tehnologija",
                        "Optimalna povezanost i sigurnost"
                    ]}
                    delay={0.3}
                />
                <ServiceItem
                    icon={FaHandsHelping}
                    title="Mali kućanski poslovi"
                    description="Sastavljanje namještaja, promjena žarulja i ostali kućanski poslovi."
                    details={[
                        "Sastavljanje namještaja",
                        "Promjena žarulja",
                        "Popravci",
                        "Postavljanje polica",
                        "Zamjena slavina",
                        "Ostali manji kućanski poslovi"
                    ]}
                    delay={0.4}
                />
            </div>
        </section>
    );
};

export default Services;
