import React, { useState } from 'react';
import { FaEnvelope, FaPhone, FaUser, FaComment } from 'react-icons/fa';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimatedItem = ({ children, delay, className }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.div
            ref={ref}
            className={className}
            initial="hidden"
            animate={controls}
            transition={{ duration: 0.5, delay }}
            variants={variants}
        >
            {children}
        </motion.div>
    );
};

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('/send_email.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(formData).toString(),
        });

        if (response.ok) {
            setStatus('Hvala! Vaša poruka je poslana. Javit ćemo Vam se u najkraćem mogućem roku.');
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: '',
            });
        } else {
            setStatus('Došlo je do problema prilikom slanja vaše poruke. Pokušajte ponovno.');
        }
    };

    return (
        <section id="contact" className="text-center py-20 bg-gray-100">
            <div className="px-4">
                <motion.h1
                    className="text-4xl font-bold mb-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Kontaktirajte nas
                </motion.h1>
                <motion.p
                    className="text-xl max-w-2xl mx-auto mb-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                >
                    Za sva pitanja i informacije slobodno nas kontaktirajte putem obrasca ispod.
                </motion.p>
            </div>
            <AnimatedItem className="max-w-lg mx-auto bg-white p-8 rounded shadow-md" delay={0.2}>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="flex items-center border-b border-gray-300 py-2">
                        <FaUser className="text-2xl text-yellow-500 mr-3" />
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Vaše ime"
                            required
                            className="w-full px-2 py-1 text-gray-700 focus:outline-none"
                        />
                    </div>
                    <div className="flex items-center border-b border-gray-300 py-2">
                        <FaEnvelope className="text-2xl text-yellow-500 mr-3" />
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Vaš email"
                            required
                            className="w-full px-2 py-1 text-gray-700 focus:outline-none"
                        />
                    </div>
                    <div className="flex items-center border-b border-gray-300 py-2">
                        <FaPhone className="text-2xl text-yellow-500 mr-3" />
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Vaš telefon"
                            required
                            className="w-full px-2 py-1 text-gray-700 focus:outline-none"
                        />
                    </div>
                    <div className="flex items-start border-b border-gray-300 py-2">
                        <FaComment className="text-2xl text-yellow-500 mr-3 mt-2" />
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Vaša poruka"
                            required
                            className="w-full px-2 py-1 text-gray-700 focus:outline-none h-32 resize-none"
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="bg-yellow-500 text-white px-6 py-2 rounded hover:bg-yellow-600 transition duration-300"
                    >
                        Pošalji
                    </button>
                </form>
                {status && <p className="mt-4 text-lg">{status}</p>}
            </AnimatedItem>
        </section>
    );
};

export default Contact;
